import React from 'react';
import loadable from '@loadable/component'

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';
const NewsletterComponent = loadable(() => import('../components/newsletter/newsletter-main'))
const Layout = loadable(() => import('../components/Layout'))
const Head = loadable(() => import('../components/Head'))


const Newsletter = () => {


    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Head title="Newsletter" />
                <NewsletterComponent />                
            </Layout>
        </ThemeProvider>
    );
};

export default Newsletter;
